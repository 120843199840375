import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { IoLogoTwitter, IoLogoVimeo, IoLogoPinterest } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import styles from "./Footer.module.scss";
import logo from "../../../assets/images/logo/voopo.png";
import logoDark from "../../../assets/images/logo/v2.png";

const Footer = ({ footerBg }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer className={`footer-area ${footerBg === "white" ? styles.footer2 : ""}`} >
      <div className={`${styles.footerTop} ${ footerBg === "white" ? "bg--cart-7" : "bg--cart-2" }`}>
        <div className="container">
          <div className="row">

              <div className={"col color--white align-content-end"}>
                <strong>All Accounts Sim LTD </strong><br />
                  <br />71-75 Shelton Street, Covent Garden
                  <br />WC2H 9JQ London<br />United Kingdom
              </div>
              <div className="col float-right">
                  <img className="float-right" src="http://allaccounts.co/img/logo-h/white/1x/logo.png" alt="" />
              </div>


          </div>
        </div>
      </div>

    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string
};

export default Footer;
