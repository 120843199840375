import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import HeroSliderOne from "../containers/hero-sliders/hero-slider-one";
import ServiceOne from "../containers/services/service-one";
import FeatureImageTextOne from "../containers/feature-image-texts/feature-image-text-one";
import ContactForm from "../containers/contact/contact-form";
import AboutContentTwo from "../containers/about-contents/about-content-two";

const HomeOne = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>All Accounts | Home</title>
        <meta
          name="description"
          content="Homepage of All Account, Telecom and Cloud Services wholesale."
        />
      </MetaTags>
      <LayoutOne>
        {/* hero slider */}
        <HeroSliderOne />
        {/* service */}
        <ServiceOne />
        {/* feature */}
        <FeatureImageTextOne />
        {/* service with text */}
        {/* software download */}

          <AboutContentTwo />
          <ContactForm />
          {/* contact map */}
      </LayoutOne>
    </Fragment>
  );
};

export default HomeOne;
