import React from "react";
import {
  FaPhone,
  FaRegEnvelope
} from "react-icons/fa";

const MobileWidgets = ({ styles }) => {
  return (
    <div className={styles.offcanvasWidgetArea}>
      <div className={styles.offCanvasContactWidget}>
        <div className={styles.headerContactInfo}>
          <ul className={styles.headerContactInfoList}>
            <li>
              <FaRegEnvelope />{" "}
              <a href="mailto:contact@allaccounts.co">contact@allaccounts.co</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileWidgets;
